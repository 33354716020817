<template>
  <ChatApp v-if="is_user_logged_in == 1"/>
</template>

<script>
// @ is an alias to /src
import ChatApp from "@/components/ChatApp.vue";

export default {
  name: "App",
  components: {
    ChatApp
  }
};
</script>

<style scoped>
</style>



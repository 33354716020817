<template>
	<div id="buc__chat-panel"  v-if="!isMinimized">
		<div id="buc__contact-profile-cont">
      <a :href="isGroup == 0 ? currentPartcipant.user_url : currentPartcipant.group_url" target="_blank">
			<img :src="currentPartcipant.avatar_url" alt="" />
			<p>{{isGroup == 0 ? currentPartcipant.fullname : currentPartcipant.group_name}}</p>         
      </a>
      <span class="buc__icon buc__icon-small" style="margin-left: 10px;" 
        @click="showSettings = !showSettings" 
        v-click-outside="hideSettings">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491.996 491.996"><defs/><path d="M484.132 124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86-7.208 0-13.964 2.792-19.036 7.86l-183.84 183.848L62.056 108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968 2.788-19.036 7.856l-16.12 16.128c-10.496 10.488-10.496 27.572 0 38.06l219.136 219.924c5.064 5.064 11.812 8.632 19.084 8.632h.084c7.212 0 13.96-3.572 19.024-8.632l218.932-219.328c5.072-5.064 7.856-12.016 7.864-19.224 0-7.212-2.792-14.068-7.864-19.128z"/></svg>
      </span>
      <div class="buc__user-menu-wrap" :class="{buc__active : showSettings}">
        <ul>
          <li>
            <a :href="isGroup == 0 ? currentPartcipant.user_url : currentPartcipant.group_url" target="_blank">
              <span class="buc__icon buc__icon-small">
                <svg viewBox="-42 0 512 512.002" xmlns="http://www.w3.org/2000/svg"><defs/><path d="M210.352 246.633c33.882 0 63.222-12.153 87.195-36.13 23.973-23.972 36.125-53.304 36.125-87.19 0-33.876-12.152-63.211-36.129-87.192C273.566 12.152 244.23 0 210.352 0c-33.887 0-63.22 12.152-87.192 36.125s-36.129 53.309-36.129 87.188c0 33.886 12.156 63.222 36.133 87.195 23.977 23.969 53.313 36.125 87.188 36.125zm0 0M426.129 393.703c-.692-9.976-2.09-20.86-4.149-32.351-2.078-11.579-4.753-22.524-7.957-32.528-3.308-10.34-7.808-20.55-13.37-30.336-5.774-10.156-12.555-19-20.165-26.277-7.957-7.613-17.699-13.734-28.965-18.2-11.226-4.44-23.668-6.69-36.976-6.69-5.227 0-10.281 2.144-20.043 8.5a2711.03 2711.03 0 01-20.879 13.46c-6.707 4.274-15.793 8.278-27.016 11.903-10.949 3.543-22.066 5.34-33.039 5.34-10.972 0-22.086-1.797-33.047-5.34-11.21-3.622-20.296-7.625-26.996-11.899-7.77-4.965-14.8-9.496-20.898-13.469-9.75-6.355-14.809-8.5-20.035-8.5-13.313 0-25.75 2.254-36.973 6.7-11.258 4.457-21.004 10.578-28.969 18.199-7.605 7.281-14.39 16.12-20.156 26.273-5.558 9.785-10.058 19.992-13.371 30.34-3.2 10.004-5.875 20.945-7.953 32.524-2.059 11.476-3.457 22.363-4.149 32.363A438.821 438.821 0 000 423.949c0 26.727 8.496 48.363 25.25 64.32 16.547 15.747 38.441 23.735 65.066 23.735h246.532c26.625 0 48.511-7.984 65.062-23.734 16.758-15.946 25.254-37.586 25.254-64.325-.004-10.316-.351-20.492-1.035-30.242zm0 0"/></svg>
              </span>
              {{isGroup == 0 ? label.view_profile : label.view_group}}
            </a>
          </li>
          <li v-if="isGroup == 1">
            <a :href="currentPartcipant.group_url + 'members/'" target="_blank">
              <span class="buc__icon buc__icon-small">
                <svg data-v-47655d0c="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999"><defs data-v-47655d0c=""></defs><path data-v-47655d0c="" d="M438.09 273.32h-39.596c4.036 11.05 6.241 22.975 6.241 35.404v149.65c0 5.182-.902 10.156-2.543 14.782h65.461c24.453 0 44.346-19.894 44.346-44.346v-81.581c.001-40.753-33.155-73.909-73.909-73.909zM107.265 308.725c0-12.43 2.205-24.354 6.241-35.404H73.91c-40.754 0-73.91 33.156-73.91 73.91v81.581c0 24.452 19.893 44.346 44.346 44.346h65.462a44.144 44.144 0 01-2.543-14.783v-149.65zM301.261 234.815h-90.522c-40.754 0-73.91 33.156-73.91 73.91v149.65c0 8.163 6.618 14.782 14.782 14.782h208.778c8.164 0 14.782-6.618 14.782-14.782v-149.65c0-40.754-33.156-73.91-73.91-73.91zM256 38.84c-49.012 0-88.886 39.874-88.886 88.887 0 33.245 18.349 62.28 45.447 77.524 12.853 7.23 27.671 11.362 43.439 11.362s30.586-4.132 43.439-11.362c27.099-15.244 45.447-44.28 45.447-77.524 0-49.012-39.874-88.887-88.886-88.887zM99.918 121.689c-36.655 0-66.475 29.82-66.475 66.475 0 36.655 29.82 66.475 66.475 66.475a66.095 66.095 0 0026.195-5.388c13.906-5.987 25.372-16.585 32.467-29.86a66.05 66.05 0 007.813-31.227c0-36.654-29.82-66.475-66.475-66.475zM412.082 121.689c-36.655 0-66.475 29.82-66.475 66.475a66.045 66.045 0 007.813 31.227c7.095 13.276 18.561 23.874 32.467 29.86a66.095 66.095 0 0026.195 5.388c36.655 0 66.475-29.82 66.475-66.475 0-36.655-29.82-66.475-66.475-66.475z"></path></svg>
              </span>
              {{label.members}}
            </a>
          </li>
          <li @click="blockUser" v-if="this.settings.chat_enable_block == '1' && isGroup == 0">
            <span class="buc__icon buc__icon-small">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><defs/><path d="M224 0C100.288 0 0 100.288 0 224s100.288 224 224 224 224-100.288 224-224S347.712 0 224 0zm-88.079 90.479A160.005 160.005 0 01224 64a159.04 159.04 0 0188.16 26.56l-221.6 221.6c-48.689-73.742-28.381-172.991 45.361-221.681zm176.158 267.042A160.002 160.002 0 01224 384a159.04 159.04 0 01-88.16-26.56l221.6-221.6c48.689 73.741 28.381 172.991-45.361 221.681z"/></svg>
            </span>
            {{currentPartcipant.is_block == true ? label.unblock : label.block}}
          </li>
          <li @click="muteGroup" v-if="isGroup == 1">
            <span class="buc__icon buc__icon-small">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><defs/><path d="M224 0C100.288 0 0 100.288 0 224s100.288 224 224 224 224-100.288 224-224S347.712 0 224 0zm-88.079 90.479A160.005 160.005 0 01224 64a159.04 159.04 0 0188.16 26.56l-221.6 221.6c-48.689-73.742-28.381-172.991 45.361-221.681zm176.158 267.042A160.002 160.002 0 01224 384a159.04 159.04 0 01-88.16-26.56l221.6-221.6c48.689 73.741 28.381 172.991-45.361 221.681z"/></svg>
            </span>
            {{currentPartcipant.sound == true ? label.mute : label.unmute}}
          </li>
          <li @click="clearConversation" v-if="isGroup == 0 || (isGroup == 1 && isAdmin())">
            <span class="buc__icon buc__icon-small">
              <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M156.371 30.906h85.57v14.399h30.903V28.89C272.848 12.96 259.894 0 243.973 0H154.34c-15.922 0-28.875 12.96-28.875 28.89v16.415h30.906zM344.21 167.75H54.103c-7.95 0-14.207 6.781-13.567 14.707L64.79 482.363C66.141 499.105 80.105 512 96.883 512h204.543c16.777 0 30.742-12.895 32.094-29.64l24.253-299.903c.645-7.926-5.613-14.707-13.562-14.707zM124.349 480.012c-.325.02-.649.031-.97.031-8.1 0-14.901-6.309-15.405-14.504l-15.2-246.207c-.523-8.52 5.957-15.852 14.473-16.375 8.488-.516 15.852 5.95 16.375 14.473l15.195 246.207c.528 8.52-5.953 15.847-14.468 16.375zm90.433-15.422c0 8.531-6.918 15.45-15.453 15.45s-15.453-6.919-15.453-15.45V218.379c0-8.535 6.918-15.453 15.453-15.453 8.531 0 15.453 6.918 15.453 15.453zm90.758-245.3l-14.512 246.206c-.48 8.211-7.293 14.543-15.41 14.543-.304 0-.613-.008-.922-.023-8.52-.504-15.02-7.817-14.515-16.336l14.508-246.211c.5-8.52 7.789-15.02 16.332-14.516 8.52.5 15.02 7.817 14.52 16.336zm92.108-99.227L387.5 89.64a19.65 19.65 0 00-18.64-13.43H29.45a19.646 19.646 0 00-18.637 13.43L.664 120.062c-1.957 5.868.59 11.852 5.344 14.836a12.624 12.624 0 006.75 1.946h372.797c2.52 0 4.816-.73 6.75-1.95 4.754-2.984 7.3-8.968 5.343-14.832zm0 0"/></svg>
            </span>
            {{label.clear_convo}}
          </li>
        </ul>
      </div>
			<div class="buc__chat-setting-wrap">
				<span class="buc__icon buc__icon-small" @click="minimizeChatWindow()">
          <svg viewBox="0 -192 469.333 469" xmlns="http://www.w3.org/2000/svg"><defs/><path d="M437.332.168H32c-17.664 0-32 14.336-32 32V53.5c0 17.664 14.336 32 32 32h405.332c17.664 0 32-14.336 32-32V32.168c0-17.664-14.336-32-32-32zm0 0"/></svg>
        </span>
				<span class="buc__icon buc__icon-small" @click="closeChatWindow()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492"><defs/><path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"/></svg>
        </span>
			</div>
		</div>
    <div id="buc__message-section" v-if="!isMediaPreview">
      <div id="buc__chat-bg-overlay" :style="{'background-image': 'url(' + plugin_url + 'assets/img/chat-bg.png)'}"></div>
      <div id="buc__message-container" ref="chatWindow">
        <ul>
          <li v-for="(item, index) in chatMessages" :key='index' 
          :class="(item.sender == my_profile.id) ? 'buc__out' : 'buc__in'">
            <template v-if="isGroup == 0"> 
              <img v-if="item.sender == my_profile.id" :src="my_profile.avatar_url" alt="" />
              <img v-else :src="currentPartcipant.avatar_url" alt="" />
            </template>
            <div class="buc__message-bubble">
              <!-- <p v-if="item.type == 'text'">{{item.message}}</p> -->
              <div class="buc__message-reply-cont" v-if="item.in_reply_to != '0'">
                <div>
                  <div class="buc__message-reply-meta">{{chatReplies[item.in_reply_to].sender}}</div>
                  <div class="buc__message-reply" v-if="chatReplies[item.in_reply_to].msgType == 'text'" v-html="chatReplies[item.in_reply_to].msg" v-linkified></div>
                  <div class="buc__message-reply" v-else >Photo</div>
                </div>
                <div class="buc__message-reply-img" v-if="chatReplies[item.in_reply_to].msgType == 'image'">
                  <img :src="chatReplies[item.in_reply_to].msg" alt="">
                </div>
              </div>
              <div style="font-weight: bold; font-style: italic;" v-if="(isGroup == 1 && item.sender != my_profile.id)">{{groupMembers[item.sender] ? groupMembers[item.sender].name : label.deleted_user}}</div>
              <div v-if="item.type == 'text'" v-html="item.message" v-linkified></div>
              <div class="buc__message-media" v-else-if="item.type == 'image'">
                <img class="buc__message-media-img" :src="item.message" alt="" @click="showModal(item.message)"> 
                <div class="buc__media-dwnld">
                  <a :href="item.message" download>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="currentColor" d="M18.9 10.3h-4V4.4H9v5.9H5l6.9 6.9 7-6.9zM5.1 19.2v2H19v-2H5.1z"></path></svg>
                  </a>
                </div>
              </div>        
              <div class="buc__message-meta">
                <span class="buc__message-meta-time" dir="auto">{{formatDate(item.date)}}</span>
                <div v-if="item.sender == my_profile.id" class="buc__message-meta-icon">
                  <span v-if="item.id == null">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15">
                      <path fill="currentColor" d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"></path>
                    </svg>
                  </span>
                  <span v-else :class="{'buc__message-meta-read' : item.isread == 1}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" width="16" height="15">
                      <path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <span class="buc__message-reply-btn" title="Reply" @click="setReply(item)">
                <svg viewBox="0 0 20 20" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.519 3.875a.54.54 0 0 1 .922-.382l4.03 4.034a.54.54 0 0 1 0 .764l-4.03 4.034a.54.54 0 0 1-.922-.383v-1.821c-3.398 0-5.886.97-7.736 3.074-.164.186-.468.028-.402-.211.954-3.449 3.284-6.67 8.138-7.363V3.875z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="buc__media-preview" v-if="isMediaPreview">
      <div class="buc__media-cont">
        <img v-bind:src="mediaURL" alt="">
        <div class="buc__progress-bar" v-if="showProgressBar">
          <div class="buc__progress-fill" :style="{width : uploadPercentage + '%'}"></div>
          <p>{{label.uploading + " " + uploadPercentage}}%</p>
        </div>
      </div>
      <span class="buc__icon-send-media" @click="sendMediaUpload">				
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <defs/><path d="M8.75 17.612v4.638a.751.751 0 001.354.444l2.713-3.692zM23.685.139a.75.75 0 00-.782-.054l-22.5 11.75a.752.752 0 00.104 1.375l6.255 2.138 13.321-11.39L9.775 16.377l10.483 3.583a.753.753 0 00.984-.599l2.75-18.5a.751.751 0 00-.307-.722z"/>
        </svg>
      </span>      
      <span class="buc__icon-cancel-media" @click="cancelMediaUpload">
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="m284.29 256 221.86-221.86c7.811-7.811 7.811-20.475 0-28.285s-20.475-7.811-28.285 0l-221.86 221.86-221.86-221.86c-7.811-7.811-20.475-7.811-28.285 0s-7.811 20.475 0 28.285l221.86 221.86-221.86 221.86c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0 0 14.143 5.857 19.94 19.94 0 0 0 14.143-5.857l221.86-221.86 221.86 221.86c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285l-221.86-221.86z"/>
        </svg>
      </span>
    </div>
    <div class="buc__in-reply-cont" v-if="inReplyTo.msgID">
			<div class="buc__in-reply-icons">
				<svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M9.519 3.875a.54.54 0 0 1 .922-.382l4.03 4.034a.54.54 0 0 1 0 .764l-4.03 4.034a.54.54 0 0 1-.922-.383v-1.821c-3.398 0-5.886.97-7.736 3.074-.164.186-.468.028-.402-.211.954-3.449 3.284-6.67 8.138-7.363V3.875z"
						fill="currentColor"
					></path>
				</svg>
			</div>
      <div class="buc__in-reply-msg-wrap">
        <div class="buc__in-reply-msg-meta">{{inReplyTo.sender}}</div>
        <div class="buc__in-reply-msg" v-if="inReplyTo.msgType == 'text'" v-html="inReplyTo.msg"></div>
        <div class="buc__in-reply-msg" v-else>{{label.photo}}</div>
      </div>
			<div class="buc__in-reply-icons">
				<div role="button" @click="inReplyTo.msgID = 0">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
						<path fill="currentColor" d="M19.1 17.2l-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"></path>
					</svg>
				</div>
			</div>
		</div>
    <div v-if="(currentPartcipant.is_block || currentPartcipant.am_i_block) && settings.chat_enable_block == '1' && isGroup == 0" class="buc__block-msg">
      <p>{{label.msg_block}}</p>
    </div>
		<div v-else-if="!isMediaPreview" class="buc__input-bar">
			<div class="buc__wrap" :class="{'buc__emoji-disabled' : settings.emoji_enable_emoji == '0', 'buc__media-disabled' : settings.media_enable == '0'}">
				<twemoji-textarea 
					:content.sync="htmlMessageContent"
					:emojiData="emojiDataAll" 
					:emojiGroups="emojiGroups"
					:placeholder="label.msg_placeholder"
					:pickerArrowEnabled="false"
          :randomEmojiArray="pickerEmojiArray"
          :recentEmojisFeat="settings.emoji_enable_recent_emoji == '1' ? true : false"
          :recentEmojisStorage="recentEmojisStorage"
          :emojiPickerDisabled="settings.emoji_enable_emoji == '0' ? true : false"
					@enterKey="onEnterKey"
					ref="twemojiTextAreaRef">
				</twemoji-textarea>
        <span class="buc__input-action" v-if="settings.media_enable == '1'" @click="$refs.buc__media.click()">
          <input type="file" v-on:change="onFileChange" accept="image/*,video/*,.pdf,.csv" ref="buc__media" style="display: none" />
          <span class="buc__icon">					
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999"><defs/><path d="M483.694 50.624c-18.473-18.679-40.704-28.526-64.357-28.526-.368 0-.737.002-1.106.007-22.542.292-44.66 9.872-62.278 26.973l-.166.161-228.705 232.522c-22.883 23.123-22.889 56.695-.014 79.825 11.093 11.216 25.244 17.392 39.848 17.392h.007c14.781-.002 29.025-6.178 40.177-17.459l158.073-161.49c7.724-7.891 7.589-20.549-.303-28.274-7.89-7.724-20.55-7.589-28.274.302l-158.004 161.42c-3.515 3.555-7.66 5.513-11.676 5.514-3.951 0-7.793-1.858-11.419-5.523-10.525-10.642-3.502-20.035.049-23.623L383.958 77.62c21.828-21.058 49.796-20.625 71.305 1.123 25.497 25.78 18.557 55.864.37 74.244-7.767 7.848-7.7 20.507.147 28.275 7.851 7.768 20.509 7.699 28.275-.148 17.548-17.732 27.467-40.276 27.928-63.475.483-24.282-9.564-48.081-28.289-67.015z"/><path d="M420.9 217.665c-7.931-7.68-20.59-7.477-28.271.456L195.287 421.935c-17.865 18.04-40.468 27.976-63.656 27.979h-.012c-23.18 0-45.172-9.846-63.594-28.472-43.121-43.599-31.082-94.804-.012-126.229l197.04-196.996c7.809-7.807 7.81-20.466.003-28.275-7.806-7.809-20.465-7.81-28.275-.003L39.703 266.973l-.075.076C14.914 292.023.856 323.513.039 355.72c-.871 34.344 12.808 66.794 39.556 93.84 25.729 26.015 58.409 40.341 92.024 40.341h.018c33.984-.005 66.713-14.184 92.157-39.925l197.562-204.04c7.681-7.933 7.477-20.591-.456-28.271z"/></svg>
          </span>
        </span>
        <span class="buc__input-submit">
          <span class="buc__icon" @click="sendMessage">					
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><defs/><path d="M8.75 17.612v4.638a.751.751 0 001.354.444l2.713-3.692zM23.685.139a.75.75 0 00-.782-.054l-22.5 11.75a.752.752 0 00.104 1.375l6.255 2.138 13.321-11.39L9.775 16.377l10.483 3.583a.753.753 0 00.984-.599l2.75-18.5a.751.751 0 00-.307-.722z"/></svg>
          </span>
        </span>
			</div>
		</div>
	</div>
</template>

<script>
import twemoji from 'twemoji';
import {
TwemojiTextarea
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/en/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';
export default {
  name: "ContentPanel",
  data() {
    return{
      htmlMessageContent: '',
      pickerEmojiArray: ['😎'],
      chatMessages: [],
      chatReplies: {},
      participantLatestID: -1,
      showSettings: false,
      mediaURL: '',
      uploadPercentage: 0,
      isMediaPreview: false,
      mediaFile: null,
      showProgressBar: false,
      recentEmojisStorage: 'local',
      inReplyTo: {
        msgID: 0,
        msg: '',
        msgType: '',
        sender: ''
      }
    }
  },
  props:{
    currentPartcipant: {
      type: Object
    },
    currentChat: {
      type: Array,
      default: ()=>[]
    },
    currentChatReplies: {
      type: Array,
      default: ()=>[]
    },
    groupMembers: {
      type: Object
    },
    senderLatestMsgID: {
      default: -1
    }, //set all message as seen till here 
    isMinimized: {
      default: false
    },
    isGroup: {
      default: 0
    }
  },
  components: {
    'twemoji-textarea': TwemojiTextarea
  },
  computed: {
        emojiDataAll() {
        return EmojiAllData;
        },
        emojiGroups() {
        return EmojiGroups;
        }
  },
  watch: {
    /** Checks new chat and if there is any differece then append the difference */
    currentChat: {
      immediate: true,
      handler(arr) {
        if(arr.length) {
          if(this.chatMessages.length && this.currentPartcipant.am_i_block && this.isGroup == 0) this.currentPartcipant.am_i_block = false;
          this.chatMessages = [...arr];
          this.scrollToLastMsg();
        }
      }
    },
    currentChatReplies: {
      immediate: true,
      handler(arr) {
        for (var i = 0;i < arr.length;i++) {
          if(!this.chatReplies[arr[i].id]) {
              this.chatReplies[arr[i].id] = {
              msgID: arr[i].id,
              msg: arr[i].message,
              msgType: arr[i].type,
              sender: this.isGroup == 0 ? 
              (( arr[i].sender == this.my_profile.id ) ? this.label.you : this.currentPartcipant.fullname) 
              : (( arr[i].sender == this.my_profile.id ) ? this.label.you : this.groupMembers[arr[i].sender].name) 
            }
          }
        }
      }
    },
    /** If window maximizes then set the message as seen */
    isMinimized: {
      immediate: true,
      handler(val) {
        if(!val && this.isGroup == 0 && this.senderLatestMsgID != -1 && this.participantLatestID < this.senderLatestMsgID) {
          this.markMessageSeen(this.senderLatestMsgID);
          this.participantLatestID = this.senderLatestMsgID;
          this.scrollToLastMsg();         
        } else if(!val) {
          this.scrollToLastMsg();
        }
      }
    }
  },
  /** Scroll to last message when chat window loads */
  mounted () {
    this.$refs.chatWindow.addEventListener('scroll', this.loadMoreChats);
  },
  /** If window is maximized and new message arrives then set the message as seen */
  updated() {
    if(!this.isMinimized && this.isGroup == 0 && this.senderLatestMsgID != -1 && this.participantLatestID < this.senderLatestMsgID) {
      this.markMessageSeen(this.senderLatestMsgID);
      this.participantLatestID = this.senderLatestMsgID;
    }
  },
  /** Remove scroll event listener */
  beforeDestroy () {
    if(this.$refs.chatWindow)
    this.$refs.chatWindow.removeEventListener('scroll', this.loadMoreChats);
  },
  methods: {
		onEnterKey(e) {
			e.stopPropagation();
      e.preventDefault();
      this.sendMessage();
    },
    /** Send a new message */
    sendMessage() {
			if (this.htmlMessageContent.replace(/&nbsp;/gi,'').trim() == ''){
        this.$refs.twemojiTextAreaRef.cleanText();	
        return false;
      }
			let newMsg = {
          id: null,
          is_group: this.isGroup,
				  sender: this.my_profile.id,
          message: this.htmlMessageContent.replace(/&nbsp;/gi,'').trim(),
          in_reply_to: this.inReplyTo.msgID,
          date: new Date().toUTCString().split(' ').slice(0, 5).join(' '),
          type: 'text',
          isread: 0
      };
      this.chatReplies[this.inReplyTo.msgID] = {
        msgID: this.inReplyTo.msgID,
        msg: this.inReplyTo.msg,
        msgType: this.inReplyTo.msgType,
        sender: this.inReplyTo.sender
      }
      let ind = this.chatMessages.push(newMsg) - 1;      
      this.scrollToLastMsg();
      this.$refs.twemojiTextAreaRef.cleanText();
      this.inReplyTo.msgID = 0; // hide reply to bar
      this.$api.get("", {params : {
        action: 'buc_send_message',
        from_user: newMsg.sender,
        to_user: this.currentPartcipant.id,
        type: newMsg.type,
        message: newMsg.message,
        in_reply_to: newMsg.in_reply_to,
        is_group: newMsg.is_group
      }})
      .then(res => {
        if(res.data.success && !this.chatMessages[ind].id) {
          this.chatMessages[ind].id = res.data.data.message_id;
        }else {
          this.currentPartcipant.am_i_block = true;
          this.chatMessages.splice(-1, 1);
        }
      })
      .catch(e => {console.log(e)});
    },
    /** load more chat on scroll top */
    loadMoreChats() {
      if (this.$refs.chatWindow.scrollTop == 0 && this.chatMessages.length) {
        this.$api
        .get("", {params: {
          action: 'buc_get_messages_between',
          user: this.currentPartcipant.id,
          offset: this.chatMessages[0].id,
          is_group: this.isGroup
        }}).then(res => {
          if (res.data.success) {
            if(res.data.data.messages.length) {
              /** Parse emoji unicode to img tags */
              res.data.data.messages.forEach((e)=> {
                e.message = twemoji.parse(e.message);
              });
              res.data.data.replies.forEach((e)=> {
                e.message = twemoji.parse(e.message);
              });

              res.data.data.messages = res.data.data.messages.sort((a, b) => a.id - b.id);
              this.chatMessages = res.data.data.messages.concat(this.chatMessages);
              let arr = res.data.data.replies;
              for (var i = 0;i < arr.length;i++) {
                if(!this.chatReplies[arr[i].id]) {
                    this.chatReplies[arr[i].id] = {
                    msgID: arr[i].id,
                    msg: arr[i].message,
                    msgType: arr[i].type,
                    sender: this.isGroup == 0 ? 
                    (( arr[i].sender == this.my_profile.id ) ? this.label.you : this.currentPartcipant.fullname) 
                    : (( arr[i].sender == this.my_profile.id ) ? this.label.you : this.groupMembers[arr[i].sender].name) 
                  }
                }
              }
              this.$refs.chatWindow.scrollTop = 100;
            }
          }
        })
        .catch(err => console.log(err));       
      }
    },
    /** Media upload validations */
    onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files[0] || !files[0].type.match('image/jpeg|image/jpg|image/png|image/gif')) {
            alert(this.label.msg_ext_issue);
            return;
        }
        if(files[0].size > (this.settings.media_max_upload * 1024 * 1024) ) {
            alert(this.label.msg_size_issue);
            return;
        }
        this.mediaFile = files[0];
        this.isMediaPreview = true;
        this.createMedia();
    },
    createMedia() {
        let reader = new FileReader();
        let vm = this;
        reader.onload = (e) => {
            vm.mediaURL = e.target.result;
        };
        reader.readAsDataURL(this.mediaFile);
    },
    /** Upload media function */
    uploadMedia() {
      let formData = new FormData();
      formData.append('action', 'buc_file_upload');
      formData.append('file', this.mediaFile);
      this.$api.post("", formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function( progressEvent ) {
          this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
        }.bind(this)
      })
      .then(res => {
        if(res.data.success) {
          this.isMediaPreview = false;
          this.mediaFile = null;
          this.mediaURL = '';
          this.showProgressBar = false;
          let newMsg = {
              id: null,
              is_group: this.isGroup,
              sender: this.my_profile.id,
              message: res.data.data.url,
              in_reply_to: this.inReplyTo.msgID,
              date: new Date().toUTCString().split(' ').slice(0, 5).join(' '),
              type: res.data.data.type,
              isread: 0
          };
          this.chatReplies[this.inReplyTo.msgID] = {
            msgID: this.inReplyTo.msgID,
            msg: this.inReplyTo.msg,
            msgType: this.inReplyTo.msgType,
            sender: this.inReplyTo.sender
          }
          let ind = this.chatMessages.push(newMsg) - 1;
          this.inReplyTo.msgID = 0; //hide reply to bar    
          this.scrollToLastMsg();
          this.$api.get("", {params : {
            action: 'buc_send_message',
            from_user: newMsg.sender,
            to_user: this.currentPartcipant.id,
            type: newMsg.type,
            message: newMsg.message,
            in_reply_to: newMsg.in_reply_to,
            is_group: newMsg.is_group
          }})
          .then(res => {
            if(res.data.success && !this.chatMessages[ind].id) {
              this.chatMessages[ind].id = res.data.data.message_id;
            } else {
              this.currentPartcipant.am_i_block = true;
              this.chatMessages.splice(-1, 1);
            }
          })
          .catch(e => {console.log(e)});
        }
      })
      .catch(e => {console.log(e)});
    },
    cancelMediaUpload() {
      this.isMediaPreview = false;
      this.mediaFile = null;
      this.mediaURL = '';
      this.scrollToLastMsg();
    },
    sendMediaUpload() {     
      this.showProgressBar = true; 
      this.uploadMedia();
    },
    setReply(msg) {
      this.inReplyTo.msgID = msg.id;
      this.inReplyTo.msg = msg.message;
      this.inReplyTo.msgType = msg.type;
      if(this.isGroup == 0)
        this.inReplyTo.sender = ( msg.sender == this.my_profile.id ) ? this.label.you : this.currentPartcipant.fullname;
      else if(this.isGroup == 1)
        this.inReplyTo.sender = ( msg.sender == this.my_profile.id ) ? this.label.you : this.groupMembers[msg.sender].name;

    },
    scrollToLastMsg() {
      setTimeout(() => {
        if(this.$refs.chatWindow) {
          this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight;
        }
      }, 200)
    },
    markMessageSeen(msgID) {
      this.$api.get("", {params: {
        action: 'buc_mark_message_read',
        sender: this.currentPartcipant.id,
        msg_id: msgID
      }}).then(res => {
        if(res.data.success) {
          this.$emit("setLocalMessageSeen", this.currentPartcipant.id, msgID);
        }
      }).catch(err => console.log(err));
    },
    blockUser() {
      this.$emit("blockUser");
    },
    muteGroup() {
      this.$emit("muteGroup");
    },
    clearConversation() {
      this.$emit("clearConversation");
    },
    clearLocalChats() {      
      this.chatMessages = [];
      this.chatReplies = {};
    },
		closeChatWindow() {
			this.$emit("closeChatWindow");
    },
    clearData(data){
      if(data === null) data = this.$options.data();
      Object.assign(this.$data, data);
    },
    minimizeChatWindow() {
			this.$emit("minimizeChatWindow");
    },
    hideSettings() {
      if(this.showSettings) this.showSettings = false;
    },
    showModal(src) {
      this.$emit("openModal", src);
    },
    isAdmin() {
      return this.currentPartcipant.group_admins.includes(this.my_profile.id);
    }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#buc__chat-panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: var(--buc-primary-text);
    -webkit-box-shadow: 0 4px 8px 1px rgba(43,43,43,.15);
    -o-box-shadow: 0 4px 8px 1px rgba(43,43,43,.15);
    -ms-box-shadow: 0 4px 8px 1px rgba(43,43,43,.15);
    box-shadow: 0 4px 8px 1px rgba(43,43,43,.15)
}

#buc__chat-panel #buc__contact-profile-cont {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    background: var(--buc-primary-shade)
}

#buc__chat-panel #buc__contact-profile-cont,#buc__chat-panel #buc__contact-profile-cont a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#buc__chat-panel #buc__contact-profile-cont img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin: 0 5px 0 10px
}

#buc__chat-panel #buc__contact-profile-cont p {
    float: left;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 150px;
    white-space: nowrap
}

#buc__chat-panel #buc__contact-profile-cont .buc__user-menu-wrap {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    line-height: initial;
    border-radius: 3px;
    z-index: 9;
    background: var(--buc-secondary-bg);
    color: var(--buc-secondary-text);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.1),0 3px 6px rgba(0,0,0,.2);
    box-shadow: 0 3px 6px rgba(0,0,0,.1),0 3px 6px rgba(0,0,0,.2);
    top: 43px;
    left: 60px;
    font-size: .9em
}

#buc__chat-panel #buc__contact-profile-cont .buc__user-menu-wrap.buc__active {
    opacity: 1;
    visibility: visible
}

#buc__chat-panel #buc__contact-profile-cont .buc__user-menu-wrap ul li {
    cursor: pointer;
    padding: 7px 15px
}

#buc__chat-panel #buc__contact-profile-cont .buc__user-menu-wrap ul li:hover {
    background: rgba(0,0,0,.15)
}

#buc__chat-panel #buc__contact-profile-cont .buc__chat-setting-wrap {
    margin-left: auto
}

#buc__chat-panel #buc__contact-profile-cont .buc__chat-setting-wrap span {
    margin-left: 10px
}

#buc__chat-panel #buc__contact-profile-cont .buc__chat-setting-wrap span:nth-last-child(1) {
    margin-right: 10px
}

#buc__chat-panel #buc__message-section {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative;
    background: var(--buc-chat-bg)
}

#buc__chat-panel #buc__message-section #buc__chat-bg-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .1
}

#buc__chat-panel #buc__message-section #buc__message-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain
}

#buc__chat-panel #buc__message-section #buc__message-container ul li {
    clear: both;
    float: left;
    margin: 5px 15px;
    width: calc(100% - 25px);
    font-size: .9em
}

#buc__chat-panel #buc__message-section #buc__message-container ul li:last-child {
    margin-bottom: 20px
}

#buc__chat-panel #buc__message-section #buc__message-container ul li:first-child {
    margin-top: 20px
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__in img {
    margin: 6px 8px 0 0
}

#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-bubble {
    display: inline-block;
    padding: 6px 7px 8px 9px;
    border-radius: 7.5px;
    max-width: 205px;
    line-height: 130%;
    -webkit-box-shadow: 0 1px .5px rgba(0,0,0,.13);
    box-shadow: 0 1px .5px rgba(0,0,0,.13);
    position: relative
}

#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-bubble:hover .buc__message-reply-btn,#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-media:hover .buc__media-dwnld {
    opacity: 1
}

#buc__chat-panel .buc__message-bubble .buc__message-reply-cont {
    background-color: rgba(0,0,0,.2);
    border-radius: 3px;
    padding: 5px 5px 5px 8px;
    margin-bottom: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%
}

#buc__chat-panel .buc__message-bubble .buc__message-reply-cont .buc__message-reply-meta {
    font-size: 12.8px;
    font-weight: 600
}

#buc__chat-panel .buc__message-bubble .buc__message-reply-cont .buc__message-reply {
    font-size: 13.2px
}

#buc__chat-panel .buc__message-bubble .buc__message-reply-cont .buc__message-reply-img {
    height: 30px;
    max-width: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    margin-left: 5px
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__message-bubble .buc__message-reply-cont .buc__message-reply-img img {
    float: none;
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0
}

#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-media {
    height: 150px;
    width: 150px
}

#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-media .buc__media-dwnld {
    opacity: 0;
    position: absolute;
    bottom: 25px;
    right: 14px;
    background: rgba(32,33,36,.6);
    color: rgba(255,255,255,.851);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-media .buc__media-dwnld svg {
    height: 20px;
    width: 20px
}

#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-media .buc__message-media-img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: unset;
    float: none;
    cursor: zoom-in;
    margin: 0
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__in .buc__message-bubble {
    color: var(--buc-recieved-chat-text);
    background: var(--buc-recieved-chat-bg);
    border-top-left-radius: 0
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__in .buc__message-bubble .buc__message-reply-cont {
    border-left: 2px solid var(--buc-recieved-chat-text)
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__in .buc__message-bubble .buc__message-reply-cont .buc__message-reply-meta {
    color: var(--buc-recieved-chat-text)
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__in .buc__message-meta,#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__in .buc__message-bubble .buc__message-reply-cont .buc__message-reply {
    color: rgba(255,255,255,.4)
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__in .buc__message-bubble .buc__message-reply-btn {
    color: var(--buc-recieved-chat-text);
    background: var(--buc-recieved-chat-bg);
    -webkit-box-shadow: 0 0 10px var(--buc-recieved-chat-bg);
    box-shadow: 0 0 10px var(--buc-recieved-chat-bg)
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__out .buc__message-bubble .buc__message-reply-btn {
    color: var(--buc-sent-chat-text);
    background: var(--buc-sent-chat-bg);
    -webkit-box-shadow: 0 0 10px var(--buc-sent-chat-bg);
    box-shadow: 0 0 10px var(--buc-sent-chat-bg)
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__out .buc__message-bubble .buc__message-reply-cont {
    border-left: 2px solid var(--buc-sent-chat-text)
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__out .buc__message-bubble .buc__message-reply-cont .buc__message-reply-meta {
    color: var(--buc-sent-chat-text)
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__out .buc__message-meta,#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__out .buc__message-bubble .buc__message-reply-cont .buc__message-reply {
    color: rgba(0,0,0,.4)
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__out img {
    float: right;
    margin: 6px 0 0 8px
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__out .buc__message-bubble {
    color: var(--buc-sent-chat-text);
    background: var(--buc-sent-chat-bg);
    float: right;
    border-top-right-radius: 0
}

#buc__chat-panel #buc__message-section #buc__message-container ul li .buc__message-bubble::after {
    content: "";
    position: absolute;
    display: block;
    border-style: solid;
    border-color: transparent;
    top: 0;
    border-width: 5px;
    margin: 0;
    bottom: auto
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__out .buc__message-bubble::after {
    border-top-color: var(--buc-sent-chat-bg);
    border-left-color: var(--buc-sent-chat-bg);
    left: auto;
    right: -10px
}

#buc__chat-panel #buc__message-section #buc__message-container ul li.buc__in .buc__message-bubble::after {
    border-top-color: var(--buc-recieved-chat-bg);
    border-right-color: var(--buc-recieved-chat-bg);
    left: -10px;
    right: auto
}

.buc__in+.buc__in .buc__message-bubble::after,.buc__out+.buc__out .buc__message-bubble::after {
    display: none!important
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__message-meta {
    float: right;
    margin: 2px -2px -5px 0;
    position: relative;
    font-size: 10px;
    height: 15px;
    line-height: 15px;
    white-space: nowrap
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__message-reply-btn {
    position: absolute;
    top: 7px;
    right: 0;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    border-radius: 50%
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__message-meta-time {
    display: inline-block;
    vertical-align: top
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__message-meta-icon {
    margin-left: 3px;
    display: inline-block;
    color: var(--bubble-meta-icon)
}

#buc__chat-panel #buc__message-section #buc__message-container .buc__message-meta-read {
    color: #4fc3f7
}

#buc__chat-panel #buc__message-section #buc__message-container ul li img {
    width: 22px;
    border-radius: 50%;
    float: left
}

#buc__chat-panel .buc__block-msg {
    text-align: center;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    line-height: 40px;
    color: var(--buc-primary-text);
    font-size: .9em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--buc-primary-hover);
    font-style: italic
}

#buc__chat-panel .buc__media-preview {
    width: 100%;
    height: calc(100% - 70px);
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    color: #292929;
    font-size: .8em;
    background: var(--buc-chat-bg)
}

#buc__chat-panel .buc__media-preview .buc__media-cont {
    width: 280px;
    height: calc(100% - 70px);
    margin: 0 auto;
    padding: 10px
}

#buc__chat-panel .buc__media-preview .buc__media-cont .buc__progress-bar {
    width: 100%;
    height: 4px;
    background: #fff;
    border-radius: 2px;
    margin-top: 10px
}

#buc__chat-panel .buc__media-preview .buc__media-cont .buc__progress-bar .buc__progress-fill {
    height: 4px;
    background: var(--buc-primary-shade)
}

#buc__chat-panel .buc__media-preview .buc__media-cont .buc__progress-bar p {
    margin-top: 5px;
    font-style: italic
}

#buc__chat-panel .buc__media-preview .buc__media-cont img {
    margin: 0 auto;
    max-height: 100%
}

#buc__chat-panel .buc__media-preview .buc__icon-cancel-media,#buc__chat-panel .buc__media-preview .buc__icon-send-media {
    color: var(--buc-primary-text);
    background: var(--buc-primary-shade);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
    float: right
}

#buc__chat-panel .buc__media-preview svg {
    height: 100%;
    fill: currentColor
}

#buc__chat-panel .buc__input-bar {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px
}

#buc__chat-panel .buc__in-reply-cont {
    background-color: #fff;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    border-bottom: 1px solid var(--buc-chat-bg)
}

#buc__chat-panel .buc__in-reply-cont .buc__in-reply-icons {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: var(--buc-primary-shade);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 32px;
    width: 32px
}

#buc__chat-panel .buc__in-reply-cont .buc__in-reply-icons div[role=button] {
    color: #919191;
    cursor: pointer;
    height: 18px;
    width: 18px
}

#buc__chat-panel .buc__in-reply-cont .buc__in-reply-msg-wrap {
    padding: 5px 10px;
    width: 100%;
    max-width: 233px
}

#buc__chat-panel .buc__in-reply-cont .buc__in-reply-msg-wrap .buc__in-reply-msg-meta {
    color: var(--buc-primary-shade);
    font-size: 12.8px;
    font-weight: 600;
    line-height: 17px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap
}

#buc__chat-panel .buc__in-reply-cont .buc__in-reply-msg-wrap .buc__in-reply-msg {
    -webkit-line-clamp: 3;
    color: rgba(0,0,0,.6);
    display: -webkit-box;
    font-size: 13.2px;
    line-height: 15px;
    max-height: 30px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical
}

#buc__chat-panel .buc__input-bar .buc__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    background-color: #fff;
    color: #292929
}

#buc__chat-panel .buc__input-bar .buc__wrap .buc__input-action,#buc__chat-panel .buc__input-bar .buc__wrap .buc__input-submit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer
}

#buc__chat-panel .buc__input-bar .buc__wrap .buc__input-action {
    opacity: .7;
    width: 10%
}

#buc__chat-panel .buc__input-bar .buc__wrap .buc__input-action:hover {
    opacity: 1
}

#buc__chat-panel .buc__input-bar .buc__wrap .buc__input-submit {
    border: 0;
    width: 15%;
    margin: 0;
    background: var(--buc-primary-shade);
    color: var(--buc-primary-text)
}

#buc__chat-panel .buc__input-bar .buc__wrap .buc__input-submit:hover {
    background: var(--buc-primary-hover)
}

#buc__chat-panel .buc__input-bar .buc__wrap .buc__input-submit:focus {
    outline: 0
}
</style>

<template>
    <div id="buc__lightbox-cont">
        <div class="buc__lightbox" @click="close">
            <span class="buc__lightbox-close" @click="close">&times;</span>
            <img :src="imageSrc">
        </div> 
    </div>
</template>

<script>
export default {
    name: "Modal",
    props: {
        imageSrc: String
    },
    methods: {
        close() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style scoped>
#buc__lightbox-cont .buc__lightbox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(38, 40, 52, 0.9);
    text-align: center;
    z-index: 99999999;
    animation: pop-in;
    animation-duration: 0.5s;
    -webkit-animation: pop-in 0.5s;
    -moz-animation: pop-in 0.5s;
    -ms-animation: pop-in 0.5s;
}

#buc__lightbox-cont .buc__lightbox img {
    position: absolute;
    top: 50%;
    max-width: 80%;
    max-height: 80vh;
    display: inline-block;
    transform: translate(-50%, -50%);    
    cursor: zoom-out;
}

#buc__lightbox-cont .buc__lightbox-close {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0px 10px;
    color: #fff;
    font-size: 35px;
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes pop-in {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes pop-in {
    0% {
        opacity: 0;
        -moz-transform: scale(0.1);
    }
    100% {
        opacity: 1;
        -moz-transform: scale(1);
    }
}
</style>